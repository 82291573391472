.section-bg {
  width: 100%;
}

.section-contenu {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding:1rem;
  gap:1rem;
}

.section-contenu > * { 
  flex: 1 1 calc(50% - 0.5rem); 
  height: 500px; 
  overflow: hidden; /* Masque les débordements */ }

.contenu-txt {
  /*padding: 1rem;*/
  display: flex;
  /* Active le mode flexbox */
  padding: 1rem 2rem;
  /* Ajoute un espacement horizontal (droite/gauche) */

}

.contenu-txt ul {
  list-style-type: disc;
  /* Enlève les puces */

  margin: 1.4rem 0;
}

.contenu-txt li {
  margin-bottom: 10px;
  /* Espacement entre les éléments de la liste */
}

.contenu-paragraphe {
  margin: auto;
  /* Centre verticalement l'élément */
}

.contenu-img {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.contenu-img img {
  flex-shrink: 0;
  width: 100%;
  height: auto;
  object-fit: cover;
  max-width: 100%;
  max-height: 100%;
  position: absolute;
}

@media (max-width: 768px) {
  .contenu-txt ul {
    /* Enlève les puces */
    padding-inline-start: 0;
  }
}

@media (max-width: 1024px) {
  
  .section-contenu {
    flex-wrap: nowrap; /* Pas de wrap */
    flex-direction: column;
    justify-content: center; 
    overflow-x: auto; /* Permet le défilement horizontal */
  }

  .section-contenu>* {
    flex: initial;
  }

  .contenu-img, .contenu-txt {
    width : 95%;
    margin: auto;
  }

  .contenu-img {
    height : 400px;
  }

  .contenu-txt {
    height: auto; /* Permet à la hauteur de s'adapter au contenu */
    max-height: none; /* Désactive toute limite définie précédemment */
  }

  .section-contenu > :nth-child(1) {
    order: 2; /* 1 devient 2 */
  }

  .section-contenu > :nth-child(2) {
    order: 1; /* 2 devient 1 */
  }

  .section-contenu > :nth-child(3) {
    order: 3; /* 3 reste 3 */
  }

  .section-contenu > :nth-child(4) {
    order: 4; /* 4 reste 4 */
  }

  .section-contenu > :nth-child(5) {
    order: 6; /* 5 devient 6 */
  }

  .section-contenu > :nth-child(6) {
    order: 5; /* 6 devient 5 */
  }

  .section-contenu > :nth-child(7) {
    order: 7; /* 7 reste 7 */
  }

  .section-contenu > :nth-child(8) {
    order: 8; /* 8 reste 8 */
  }
}
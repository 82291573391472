.button {
    background-color: #022521;
    color: #EDE9DE;
    padding: 0.5rem 1rem;
    border: solid #022521;
    border-radius: 8px;
    cursor: pointer;
    transition: all 0.5s ease-out;
    width: 220px;
    font-size: 1rem;
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);
    margin: 1.4rem auto;
    display: block;

}

.button:hover {
    background-color: #EDE9DE;
    color: #022521;
}

@media only screen and (max-width: 768px) {
    .button {
        font-size: 0.85rem;
    }
}
.presentation-img {
    margin: auto;
}

.presentation-img img {
    max-width: 290px;
}

.presentation-text {
    margin :auto;
}

.section-presentation {
    max-width: 700px;
    margin: auto;
    padding: 1rem;
}
.navbar {
    background-color: #022521;
    display: flex;
    justify-content: center;
    position: sticky;
    /* Fait en sorte que l'élément reste fixe en haut */
    top: 0;
    z-index: 9999;
    /* Définit la position collante en haut de la page */
}

.navbar-logo img {
    width: 120px;
    height: auto;
    image-rendering: auto;
    /* Ajuste la qualité selon vos besoins */
}

.navbar-links {
    margin: auto 25px;
}

.navbar-links ul {
    padding-inline-start: 0;
}

.navbar-links li {
    display: inline;

}

.navbar-links li a {
    color: #EDE9DE;
    position: relative;
    letter-spacing: 0.04rem;
    margin: 1rem;
    /* Ajoutez cela pour permettre le positionnement de ::after */
}

.navbar-links li.active a::after {
    content: "";
    position: absolute;
    width: 30px;
    height: 2px;
    background-color: #EDE9DE;
    bottom: -4px;
    left: 0;
    border-radius: 100px;
}

.menu-icon {
    display: none;
    margin: auto 3rem;
    background: #022521;
    cursor: pointer;
    height: 1.7rem;
    position: relative;
}

.bar {
    width: 2rem;
    height: 0.2rem;
    background-color: #EDE9DE;
    border-radius: 4px;
    transition: 0.5s;
}

.menu-icon.open .bar:nth-child(2) {
    transform: translate(-0.5rem, 0);
}

/* Responsivness */
@media only screen and (max-width: 1200px) {
    .navbar {
        justify-content: space-between;
        /* Ajustez la valeur selon vos besoins */
    }

    .navbar-logo img {
        max-width: 120px !important;
        margin: auto 3rem !important;
    }

    .menu-icon {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    .navbar-links {
        display: none;
        background-color: #022521;
        position: absolute;
        right: 0;
    }

    .navbar-links.open {
        display: block;
        top: 120px;
        margin: 0;
        border-bottom-left-radius: 1.6rem;
        border-bottom-right-radius: 1.6rem;
        /*position: relative; /* Assurez-vous que z-index est pris en compte */
        z-index: 9999;
        /* Ajustez la valeur selon vos besoins */
        width: 100%;
    }

    .navbar-links.open ul {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .navbar-links.open ul>* {
        margin: 1rem;
    }
}